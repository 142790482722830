import React, { useState } from 'react'
import classes from "./styles/LeftSidebar.module.css";
import downImg from "../assets/next.png";
import { NavLink } from 'react-router-dom';

const LeftSidebar = () => {
    // const [box1 , setBox1] = useState(false);
    // const [box2 , setBox2] = useState(false);
    // const [box3 , setBox3] = useState(false);
    const [box4 , setBox4] = useState(false);
    // const [box5 , setBox5] = useState(false);
    const [box6 , setBox6] = useState(false);
    const [box7 , setBox7] = useState(false);

    // const handlebox1 = () => {
    //     setBox1(!box1);
    // }
    // const handlebox2 = () => {
    //     setBox2(!box2);
    // }

    // const handlebox3 = () => {
    //     setBox3(!box3);
    // }
    const handlebox4 = () => {
        setBox4(!box4);
    }
    // const handlebox5 = () => {
    //     setBox5(!box5);
    // }
    const handlebox6 = () => {
        setBox6(!box6);
    }
    const handlebox7 = () => {
        setBox7(!box7);
    }

  return (
    <div className={classes.container}>
       {/* <div className={box1 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox1}>
                <div className={`${classes.logoCon} ${box1 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Design Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                </div>
            </div>
        </div>
        <div className={box2 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox2}>
                <div className={`${classes.logoCon} ${box2 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Web Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div></div>
            </div>
        </div>
        <div className={box3 ? classes.boxOpen : classes.box}>
            <div className={classes.upperCon} onClick={handlebox3}>
                <div className={`${classes.logoCon} ${box3 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>PDF Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div></div>
            </div>
        </div>*/}
        <div className={box4 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox4}>
                <div className={`${classes.logoCon} ${box4 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Text Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                <NavLink className={classes.subCategory}>Counters</NavLink>
                <NavLink to="/text-tools/word-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Word Counter</NavLink>
                <NavLink to="/text-tools/character-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Character Counter</NavLink>
                <NavLink to="/text-tools/line-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Line Counter</NavLink>
                <NavLink to="/text-tools/letter-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Letter Counter</NavLink>

                <NavLink className={classes.subCategory}>Case Converters</NavLink>
                <NavLink to="/text-tools/uppercase" className={({ isActive }) => (isActive ? classes.active : "")}>Uppercase Converter</NavLink>
                <NavLink to="/text-tools/lowercase" className={({ isActive }) => (isActive ? classes.active : "")}>Lowercase Converter</NavLink>
                <NavLink to="/text-tools/capitalise" className={({ isActive }) => (isActive ? classes.active : "")}>Capitalise Converter</NavLink>
                </div>
            </div>
        </div>
        {/*
        <div className={box5 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox5}>
                <div className={`${classes.logoCon} ${box5 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Productivity Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div></div>
            </div>
        </div>*/}
        <div className={box6 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox6}>
                <div className={`${classes.logoCon} ${box6 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Calculators</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                    <NavLink className={classes.subCategory}>Time</NavLink>
                    <NavLink to="/calculators/age-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Age Calculator</NavLink>
                    <NavLink to="/calculators/birthday-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Birthday Calculator</NavLink>
                </div>
            </div>
        </div> 

        <div className={box7 ? classes.boxOpen : classes.box}>
            <div className={classes.upperCon} onClick={handlebox7}>
                <div className={`${classes.logoCon} ${box7 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Converters</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                    <NavLink className={classes.subCategory}>Number Converters</NavLink>
                    <NavLink to="/converters/number/binary-to-decimal" className={({ isActive }) => (isActive ? classes.active : "")}>Binary to decimal</NavLink>
                    <NavLink to="/converters/number/decimal-to-binary" className={({ isActive }) => (isActive ? classes.active : "")}> Decimal to binary</NavLink>
                    <NavLink className={classes.subCategory}>Temperature Converters</NavLink>
                    <NavLink to="/converters/temperature/celsius-to-fahrenheit" className={({ isActive }) => (isActive ? classes.active : "")}>Celsius to fahrenheit</NavLink>
                    <NavLink to="/converters/temperature/celsius-to-kelvin" className={({ isActive }) => (isActive ? classes.active : "")}>Celsius to kelvin</NavLink>
                    <NavLink to="/converters/temperature/fahrenheit-to-celsius" className={({ isActive }) => (isActive ? classes.active : "")}>Fahrenheit to celsius</NavLink>
                    <NavLink to="/converters/temperature/fahrenheit-to-kelvin" className={({ isActive }) => (isActive ? classes.active : "")}>Fahrenheit to kelvin</NavLink>
                    <NavLink to="/converters/temperature/kelvin-to-celsius" className={({ isActive }) => (isActive ? classes.active : "")}>Kelvin to celsius</NavLink>
                    <NavLink to="/converters/temperature/kelvin-to-fahrenheit" className={({ isActive }) => (isActive ? classes.active : "")}>Kelvin to fahrenheit</NavLink>

                    <NavLink className={classes.subCategory}>Weight Converters</NavLink>
                    <NavLink to="/converters/weight/microgram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to milligram</NavLink>
                    <NavLink to="/converters/weight/microgram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to gram</NavLink>
                    <NavLink to="/converters/weight/microgram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to kilogram</NavLink>
                    <NavLink to="/converters/weight/microgram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to metric ton</NavLink>
                    <NavLink to="/converters/weight/microgram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to ounce</NavLink>
                    <NavLink to="/converters/weight/microgram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to pound</NavLink>
                    <NavLink to="/converters/weight/microgram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to stone</NavLink>
                    <NavLink to="/converters/weight/microgram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to ton</NavLink>

                    <NavLink to="/converters/weight/milligram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to microgram</NavLink>
                    <NavLink to="/converters/weight/milligram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to gram</NavLink>
                    <NavLink to="/converters/weight/milligram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to kilogram</NavLink>
                    <NavLink to="/converters/weight/milligram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to metric ton</NavLink>
                    <NavLink to="/converters/weight/milligram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to ounce</NavLink>
                    <NavLink to="/converters/weight/milligram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to pound</NavLink>
                    <NavLink to="/converters/weight/milligram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to stone</NavLink>
                    <NavLink to="/converters/weight/milligram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to ton</NavLink>
                    
                    <NavLink to="/converters/weight/gram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to microgram</NavLink>
                    <NavLink to="/converters/weight/gram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to milligram</NavLink>
                    <NavLink to="/converters/weight/gram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to kilogram</NavLink>
                    <NavLink to="/converters/weight/gram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to metricton</NavLink>
                    <NavLink to="/converters/weight/gram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to ounce</NavLink>
                    <NavLink to="/converters/weight/gram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to pound</NavLink>
                    <NavLink to="/converters/weight/gram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to stone</NavLink>
                    <NavLink to="/converters/weight/gram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to ton</NavLink>

                    <NavLink to="/converters/weight/kilogram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to microgram</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to milligram</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to gram</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to metric ton</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to ounce</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to pound</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to stone</NavLink>
                    <NavLink to="/converters/weight/kilogram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to ton</NavLink>

                    <NavLink to="/converters/weight/metricton-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to microgram</NavLink>
                    <NavLink to="/converters/weight/metricton-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to milligram</NavLink>
                    <NavLink to="/converters/weight/metricton-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to gram</NavLink>
                    <NavLink to="/converters/weight/metricton-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to kilogram</NavLink>
                    <NavLink to="/converters/weight/metricton-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to ounce</NavLink>
                    <NavLink to="/converters/weight/metricton-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to pound</NavLink>
                    <NavLink to="/converters/weight/metricton-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to stone</NavLink>
                    <NavLink to="/converters/weight/metricton-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to ton</NavLink>

                    <NavLink to="/converters/weight/ounce-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to microgram</NavLink>
                    <NavLink to="/converters/weight/ounce-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to milligram</NavLink>
                    <NavLink to="/converters/weight/ounce-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to gram</NavLink>
                    <NavLink to="/converters/weight/ounce-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to kilogram</NavLink>
                    <NavLink to="/converters/weight/ounce-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to metricton</NavLink>
                    <NavLink to="/converters/weight/ounce-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to pound</NavLink>
                    <NavLink to="/converters/weight/ounce-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to stone</NavLink>
                    <NavLink to="/converters/weight/ounce-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to ton</NavLink>

                    <NavLink to="/converters/weight/pound-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to microgram</NavLink>
                    <NavLink to="/converters/weight/pound-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to milligram</NavLink>
                    <NavLink to="/converters/weight/pound-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to gram</NavLink>
                    <NavLink to="/converters/weight/pound-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to kilogram</NavLink>
                    <NavLink to="/converters/weight/pound-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to metricton</NavLink>
                    <NavLink to="/converters/weight/pound-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to ounce</NavLink>
                    <NavLink to="/converters/weight/pound-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to stone</NavLink>
                    <NavLink to="/converters/weight/pound-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to ton</NavLink>

                    <NavLink to="/converters/weight/stone-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to microgram</NavLink>
                    <NavLink to="/converters/weight/stone-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to milligram</NavLink>
                    <NavLink to="/converters/weight/stone-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to gram</NavLink>
                    <NavLink to="/converters/weight/stone-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to kilogram</NavLink>
                    <NavLink to="/converters/weight/stone-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to metricton</NavLink>
                    <NavLink to="/converters/weight/stone-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to ounce</NavLink>
                    <NavLink to="/converters/weight/stone-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to pound</NavLink>
                    <NavLink to="/converters/weight/stone-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to ton</NavLink>

                    <NavLink to="/converters/weight/ton-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to microgram</NavLink>
                    <NavLink to="/converters/weight/ton-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to milligram</NavLink>
                    <NavLink to="/converters/weight/ton-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to gram</NavLink>
                    <NavLink to="/converters/weight/ton-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to kilogram</NavLink>
                    <NavLink to="/converters/weight/ton-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to metricton</NavLink>
                    <NavLink to="/converters/weight/ton-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to ounce</NavLink>
                    <NavLink to="/converters/weight/ton-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to pound</NavLink>
                    <NavLink to="/converters/weight/ton-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to stone</NavLink>

                </div>
            </div>
        </div>
    </div>
  )
}

export default LeftSidebar
