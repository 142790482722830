import React, { useState } from 'react'
import classes from "./styles/Navbar.module.css";
import { Link, NavLink } from 'react-router-dom';
import logo from "../assets/logo4.png";
import LeftSidebar from './LeftSidebar';

const Navbar = () => {
  const [isMenuOpen , setIsMenuOpen] = useState(false);

  return (
    <div>
        <div className={classes.InnerContainer}>
            <Link to="/"><div className={classes.logoCon}><img src={logo} alt='logo'/></div></Link>
            <div className={classes.pagesCon}>
                {/* <Link className={classes.link}>Design</Link>
                <Link className={classes.link}>Web</Link>
                <Link className={classes.link}>PDF</Link>
                <Link className={classes.link}>Text</Link>
                <Link className={classes.link}>Productivity</Link>
                <Link className={classes.link}>Calculators</Link> */}
                <NavLink className={({ isActive }) => (isActive ? classes.activelink : classes.link)} to="/text-tools">Text</NavLink>
                <NavLink className={({ isActive }) => (isActive ? classes.activelink : classes.link)} to="/calculators">Calculators</NavLink>
                <NavLink className={({ isActive }) => (isActive ? classes.activelink : classes.link)} to="/converters">Converters</NavLink>
            </div>
        </div>

        <div className={classes.InnerContainerMobile}>
            <div className={classes.menuIconDiv} onClick={() => setIsMenuOpen(!isMenuOpen)}><img src={!isMenuOpen ? 'https://cdn-icons-png.flaticon.com/128/12314/12314149.png' : "https://cdn-icons-png.flaticon.com/128/9675/9675141.png"} alt='hamburger-icon'/></div>
            <div className={classes.logoCon} ><img src={logo} alt='logo'/></div>
            {isMenuOpen ? <div className={classes.LeftSidebarCon}><LeftSidebar/></div> : "" }
        </div>
    </div>
  )
}

export default Navbar;
