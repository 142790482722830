import React from 'react'
import classes from "./styles/Footer.module.css";
import logo from "../assets/logo3.png";
import instagram from "../assets/social/instagram.png";
import facebook from "../assets/social/facebook.png";
import linkedin from "../assets/social/linkedin.png";
import { Link} from 'react-router-dom';

const Footer = () => {

  return (
    <div className={classes.Container}>
        <div className={classes.innerCon}>
      <div className={classes.firstDiv}>
        <div><Link to="/"><img src={logo} alt='wiraa-copyright'/></Link></div>
        <p>© 2024 All right reserved.</p>
      </div>
      <div className={classes.secondDiv}>
        <a href='https://www.wiraa.com/#/about' target='_blank' rel="noopener noreferrer">About</a>
        <a href='https://www.wiraa.com/#/privacy-policy' target='_blank' rel="noopener noreferrer">Privacy</a>
      </div>
      <div className={classes.secondDiv}>
        <a href='https://www.wiraa.com/?type=workfromhome-jobs' target='_blank' rel="noopener noreferrer">Jobs</a>
        <a href='https://blog.wiraa.com/' target='_blank' rel="noopener noreferrer">Insights</a>
      </div>
      {/* <div className={classes.thirdDiv}>
        <a href='#/learn'>Learn</a>
        <a onClick={SrolltoPackage}>Get Started</a>
      </div> */}
      <div className={classes.fourthDiv}>
        <p>Social</p>
        <div>
            <img src={instagram} alt='instagram-logo' onClick={() =>  window.open('https://www.instagram.com/wiraaworld', '_blank')}/>
            <img src={facebook} alt='facebook-logo' onClick={() =>  window.open('https://www.facebook.com/wiraaworld', '_blank')}/>
            <img src={linkedin} alt='linkedin-logo' onClick={() =>  window.open('https://www.linkedin.com/company/wiraa', '_blank')}/>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Footer;
